import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import isEqual from 'lodash/isEqual';
import { react } from '@appbaseio/reactivesearch/lib/types';

// App specific imports
import DatasetPointsMap, {
  DatasetsPoint,
  SearchArea,
  Point,
} from '@extensions/components/dataset-search/DatasetPointsMap';

export const AREA_FILTER_REACTIVE_ID = 'AreaFilter';

export interface IAreaFilterProps {
  showMap: boolean;
  // onInitialMapLoad?: () => void;
  react?: react;
}

class AttachedAreaFilter extends React.Component<{
  error: any;
  loading: boolean;
  data: any[];
  value: string;
  setQuery: ({ query, value }: { query: any; value: string }) => void;
  showMap: boolean;
  // onInitialMapLoad: () => void;
}> {
  haveCalledOnInitialMapLoad: boolean = false;

  componentDidMount() {
    this.updateAfterValueChange();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.updateAfterValueChange();
    }
    if (
      !isEqual(prevProps.loading, this.props.loading) &&
      this.props.loading === false &&
      this.haveCalledOnInitialMapLoad === false
    ) {
      this.haveCalledOnInitialMapLoad = true;
      // this.props.onInitialMapLoad();
    }
  }

  updateAfterValueChange = () => {
    const { value, setQuery } = this.props;
    this.setSearchArea({ searchArea: this.decodeValue(value), setQuery });
  };

  encodeValue = (value: SearchArea | null): string | null => {
    if (value) {
      const encodePoint = (point: Point): string => `${point.lat},${point.lon}`;
      return `${encodePoint(value.northWestCorner)} to ${encodePoint(
        value.southEastCorner
      )}`;
    }
    return null;
  };

  decodeValue = (value: string): SearchArea | null => {
    if (value) {
      const [northWestCornerStr, southEastCornerStr] = value.split(' to ');
      const decodePoint = (point: string): Point => {
        const [latStr, lonStr] = point.split(',');
        return {
          lat: +latStr,
          lon: +lonStr,
        };
      };

      return {
        northWestCorner: decodePoint(northWestCornerStr),
        southEastCorner: decodePoint(southEastCornerStr),
      };
    }
    return null;
  };

  setSearchArea = ({ searchArea, setQuery }) => {
    if (searchArea) {
      setQuery({
        _source: {
          includes: ['identifier', 'spatial'],
        },
        query: {
          bool: {
            filter: {
              geo_bounding_box: {
                spatial: {
                  top_left: {
                    lat: searchArea.northWestCorner.lat,
                    lon: searchArea.northWestCorner.lon,
                  },
                  bottom_right: {
                    lat: searchArea.southEastCorner.lat,
                    lon: searchArea.southEastCorner.lon,
                  },
                },
              },
            },
          },
        },
        value: this.encodeValue(searchArea),
      });
    } else {
      setQuery({
        query: {
          match_all: {},
        },
        value: null,
      });
    }
  };

  render() {
    const { error, loading, data, value, setQuery, showMap } = this.props;

    let points: DatasetsPoint[] = [];
    const dataSuccessfullyRetrieved = !loading && !error;
    if (dataSuccessfullyRetrieved) {
      const byLoc = data.reduce((byLoc, hit) => {
        if (!hit.spatial || !hit.spatial.length) {
          return byLoc;
        }
        hit.spatial.forEach((point: [number, number]) => {
          const id = `${point[1]}${point[0]}`;
          if (!byLoc.hasOwnProperty(id)) {
            byLoc[id] = {
              datasets: [],
              lat: point[1],
              lon: point[0],
            };
          }
          byLoc[id].datasets.push({
            name: hit.name,
            longName: hit.title,
          });
        });
        return byLoc;
      }, {})
      // It's important for the sorts to match in order to avoid constant rerenders
      // DatasetsPointsMap uses _.isEqual() to determine if the points change
      points = Object.values(byLoc);
      points.forEach(p => p.datasets.sort((a, b) => a.name.localeCompare(b.name)));
      points.sort((a, b) => `${a.lat}${a.lon}`.localeCompare(`${b.lat}${b.lon}`));
    }

    return (
      <div>
        <DatasetPointsMap
          points={points}
          visible={showMap && dataSuccessfullyRetrieved}
          handleSearchThisArea={(newArea) =>
            this.setSearchArea({ searchArea: newArea, setQuery })
          }
          currentSearchArea={this.decodeValue(value)}
        />
        <p style={{fontSize: '0.75rem'}}>
          Green numbers indicate multiple datasets close to each other. Zoom in to see individual datasets, represented in blue.
        </p>
      </div>
    );
  }
}

export default class AreaFilter extends React.Component<IAreaFilterProps> {
  render() {
    const {
      showMap,
      // onInitialMapLoad,
      react
    } = this.props;
    return (
      <ReactiveComponent
        componentId={AREA_FILTER_REACTIVE_ID}
        URLParams={true}
        filterLabel="Area"
        defaultQuery={(...args) => ({
          query: {
            bool: {
              filter: {
                exists: {
                  field: 'spatial',
                },
              },
            },
          },
          size: 500,
        })}
        react={react}
        render={({ error, loading, data, value, setQuery }) => {
          return (
            <AttachedAreaFilter
              error={error}
              loading={loading}
              data={data}
              value={value}
              setQuery={setQuery}
              showMap={showMap}
              // onInitialMapLoad={onInitialMapLoad}
            />
          );
        }}
      />
    )
  }
}
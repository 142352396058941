import { default as BaseDatasetSearch, ISearchProps } from '@dapclient/components/page/DatasetSearch';
import AreaFilter from '@dapclient/components/dataset-search/AreaFilter';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';

const searchFieldLabels = {
  'llmd.name': 'Table Names',
  'llmd.description': 'Table Descriptions',
  'llmd.attributes.name': 'Table Column Names',
  'llmd.attributes.values': 'Table Column Values',
  'llmd.attributes.description': 'Table Column Descriptions',
}

const PARTICIPATING_ORGS_REACTIVE_ID = 'PARTICIPATING_ORGS';
const KEYWORDS_REACTIVE_ID = 'keywords-filter';
const PROJECT_REACTIVE_ID = 'project-filter';
const DATA_ACCESS_REACTIVE_ID = 'dataAccess-filter';
const AREA_FILTER_REACTIVE_ID = 'AreaFilter';
const SEARCH_BAR_REACTIVE_ID = 'SearchBar';

export const REACTIVE_IDS = [
  PARTICIPATING_ORGS_REACTIVE_ID,
  KEYWORDS_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  DATA_ACCESS_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
];

const DatasetSearch = (props: ISearchProps) => {
  const allReactiveIdsExcept = getAllReactiveIdsExcept([
    ...REACTIVE_IDS,
    SEARCH_BAR_REACTIVE_ID,
  ]);

  return (
    <BaseDatasetSearch
      searchBarProps={{
        dataField: [...Object.keys(searchFieldLabels)],
        highlight: true,
        customHighlight: d => ({
          highlight: {
            pre_tags: ['<mark>'],
            post_tags: ['</mark>'],
            fields: {
              ...d.dataField.reduce((carry, field) => {
                return {
                  ...carry,
                  [field]: (field === 'description' || field === 'title')
                    ? { number_of_fragments: 0 }
                    : {}
                }
              }, {}),
            }
          },
        })
      }}
      searchFieldLabels={searchFieldLabels as any}
      {...props}
      renderAreaFilter={() => <AreaFilter showMap={true} react={{
        and: allReactiveIdsExcept(AREA_FILTER_REACTIVE_ID)
      }} />}
    />
  )
}

export default (DatasetSearch)

import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, makeObservable } from 'mobx';

import {
  Grid,
  Alert,
  ScopedCssBaseline,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

import { ISecurityService } from '@extensions/services/ISecurityService';

import {
  SEARCH_BAR_REACTIVE_ID,
  SearchBar,
  SearchProvider,
  SearchResults,
  SelectedFilters,
} from '@extensions/components/search-core';
import Dataset from '@extensions/models/Dataset';
import DatasetFilters, {
  REACTIVE_IDS as FILTER_REACTIVE_IDS,
} from '@extensions/components/dataset-search/DatasetFilters';
import SignInLink from '@extensions/components/core/SignInLink';
import DatasetGuard from '@extensions/components/core/DatasetGuard';
import DatasetResult from '@extensions/components/dataset-search/DatasetResult';
import { IResultsListProps } from '@extensions/components/search-core/SearchResults';
import { SearchResultStats } from '@extensions/components/search-core/PrettyResultStats';
import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';

export interface ISearchProps { }
export interface ISearchState { }

interface ElasticDatasetHit {
  identifier: string;
  contactPoint: [
    {
      hasEmail: string;
      fn: string;
      hasRole: string;
      hasOrg: string;
    }
  ];
  description: string;
  title: string;
  name: string;
  project_name: string;
  project: {
    participatingOrganizations: Array<{
      name: string;
    }>;
  };
  shortName: string;
  keyword: string[];
  dapAccessRequiresMfa: boolean;
  distributionType: string[];
  dapFileSummary: { count: number; size: number };
  highlight?: { string: string[] };
}

const StyledSelectedFilters = styled(SelectedFilters)(() => ({
  marginBottom: theme.spacing(2),
}));

const StyledSearchBarDiv = styled('div')(() => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

const StyledDatasetResultDiv = styled('div')(() => ({
  marginBottom: theme.spacing(3),
}));

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedInfo,
}));

export interface ISearchProps {
  projectContext?: string;
  className?: string;
  searchFieldLabels?: { string: string };
  searchBarProps?: Omit<
    DataSearchProps,
    'componentId'
    | 'innerClass'
  >;
  resultProps?: Omit<
    IResultsListProps,
    'notificationService'
    | 'tokenService'
    | 'dataField'
  >;
  securityService?: ISecurityService;
  renderAreaFilter?(): any;
}

export const defaultSearchFields: any = {
  name: 'Dataset Name',
  description: 'Description',
  title: 'Title',
  keyword: 'Keyword',
  distributionType: 'Distribution Type',
  project_name: 'Project Name',
  'project.participatingOrganizations.name': 'Participating Organization',
  'contactPoint.fn': 'Contact Name',
  'contactPoint.hasOrg': 'Contact Organization',
}

@observer
export class DatasetSearch extends React.Component<
  ISearchProps,
  ISearchState
> {
  @observable
  resultStats: SearchResultStats | null = null;

  elasticHitToDatasets: (hit: ElasticDatasetHit) => Dataset = (
    hit: ElasticDatasetHit
  ) => {
    const dataset = new Dataset({
      meta: hit,
      name: hit.name,
      project_name: hit.project_name,
      participatingOrganizations: (hit.project && hit.project.participatingOrganizations) ? hit.project.participatingOrganizations : []
    });
    dataset.setDynamoFileCount(hit.dapFileSummary.count);
    dataset.setDynamoTotalFileSize(hit.dapFileSummary.size);
    dataset.setAccessMethods(hit.distributionType);
    return dataset;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      projectContext,
      securityService,
      searchBarProps,
      searchFieldLabels,
      resultProps,
    } = this.props;
    const { dataField, ...restSearchBarProps } = searchBarProps || {
      dataField: [],
    };
    const body = (
      <ScopedCssBaseline>
        {securityService !== undefined && securityService.userIsLoggedIn === false && (
          <StyledAlert severity="info">
            <strong>NOTE</strong>: Only public datasets are currently
            visible.&nbsp;
            <SignInLink styleObject={{ verticalAlign: 'top' }}>Sign in</SignInLink> to browse any others accessible to
            you.
          </StyledAlert>
        )}
        <SearchProvider
          elasticIndex="datasets"
          apiPrefix="api"
          theme={{
            colors: {
              primaryColor: '#53b03f',
            },
          }}
        >
          <StyledSearchBarDiv>
            <SearchBar
              dataField={[
                ...new Set([
                  ...Object.keys(defaultSearchFields),
                  ...dataField || [],
                ])
              ]}
              autosuggest={false}
              URLParams={true}
              queryFormat="and"
              {...restSearchBarProps}
            />
          </StyledSearchBarDiv>
          <StyledSelectedFilters />
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <DatasetFilters projectContext={projectContext} />
            </Grid>
            <Grid item xs={8}>
              {this.props.renderAreaFilter && this.props.renderAreaFilter()}
              <SearchResults
                resultsText="datasets"
                dataField="title.keyword"
                sortBy="asc"
                sortOptions={[
                  {
                    label: 'Last Updated',
                    dataField: 'dapFileSummary.updated',
                    sortBy: 'desc',
                  },
                  {
                    label: 'Name A-Z',
                    dataField: 'title.keyword',
                    sortBy: 'asc',
                  },
                  {
                    label: 'Name Z-A',
                    dataField: 'title.keyword',
                    sortBy: 'desc',
                  },
                  {
                    label: 'Volume Ascending',
                    dataField: 'dapFileSummary.size',
                    sortBy: 'asc',
                  },
                  {
                    label: 'Volume Descending',
                    dataField: 'dapFileSummary.size',
                    sortBy: 'desc',
                  },
                  {
                    label: 'File Count Ascending',
                    dataField: 'dapFileSummary.count',
                    sortBy: 'asc',
                  },
                  {
                    label: 'File Count Descending',
                    dataField: 'dapFileSummary.count',
                    sortBy: 'desc',
                  },
                  {
                    label: 'Best Match',
                    dataField: '_score',
                    sortBy: 'desc',
                  },
                ]}
                react={{
                  and: [SEARCH_BAR_REACTIVE_ID, ...FILTER_REACTIVE_IDS],
                }}
                renderItem={(hit) => {
                  const dataset = this.elasticHitToDatasets(hit);
                  return (
                    <StyledDatasetResultDiv>
                      <DatasetResult
                        key={dataset.name}
                        data={dataset}
                        highlight={hit.highlight}
                        renderCounts={hit.distributionType.some(
                          (dist) => dist === 'Download'
                        )}
                        searchFieldLabels={{
                          ...defaultSearchFields,
                          ...searchFieldLabels,
                        }}
                      />
                    </StyledDatasetResultDiv>
                  );
                }}
                renderNoResults={() => {
                  if (projectContext) {
                    return (
                      <DatasetGuard>
                        No results found.
                      </DatasetGuard>
                    );
                  }
                  return "No results found.";
                }}
                {...resultProps}
              />
            </Grid>
          </Grid>
        </SearchProvider>
      </ScopedCssBaseline>
    );
    return !process.env.REACT_APP_SHOW_PUBLIC_DS ? (
      <DatasetGuard>{body}</DatasetGuard>
    ) : (
      body
    );
  }
}

export default inject((store: any) => ({
  // Put any services you need here
  securityService: store.securityService,
}))(DatasetSearch);
